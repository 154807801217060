<template>
  <div>
    <inscripcion-cursado-a-b-m-alum :headers="headers" :user="user">
      <template v-slot:other-form>
        <cursada-form
          :errors="errors"
          :item="cursado"
          @guardar="guardar"
        ></cursada-form>
      </template>

      <template v-slot:item.cuatrimestre>
        {{ cuatrimestre[cursado.cuatrimestre] }}
      </template>

      <template v-slot:item.inscripcion="{ item }">
        <v-btn
          @click="showInscripcion(item)"
          outlined
          rounded
          :color="item.inscripciones ? 'success' : 'error'"
        >
          Inscr
        </v-btn>
      </template>
      <template v-slot:item.alumnos="{ item }">
        <!--        <v-btn @click="$router.push('alumno-cursada/'+item.id)" outlined rounded color="primary">-->
        <v-btn
          @click="$router.push('/alumno-cursada/' + item.id)"
          outlined
          rounded
          color="primary"
        >
          Alum
        </v-btn>
      </template>

      <template v-slot:item.docentes="{ item }">
        <span v-for="docente in item.docentes" :key="docente.id">
          {{ docente.apellido }}
        </span>
      </template>
      <template v-slot:item.horarios="{ item }">
        <span v-for="horario in item.horarios" :key="horario.id">
          {{ dias[horario.dia].text }} de {{ horario.hora_inicio }} a
          {{ horario.hora_fin }}
        </span>
      </template>
    </inscripcion-cursado-a-b-m-alum>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import InscripcionCursadoABMAlum from "@/flavor/alumno/views/Inscripciones/InscripcionCursadoABMAlum";

export default {
  name: "InscripcionCursadosAlum",
  props: {
    user: Object,
  },
  components: { InscripcionCursadoABMAlum },
  data() {
    return {
      dias: [
        { text: "Domingo", value: 0 },
        { text: "Lunes", value: 1 },
        { text: "Martes", value: 2 },
        { text: "Miercoles", value: 3 },
        { text: "Jueves", value: 4 },
        { text: "Viernes", value: 5 },
        { text: "Sabado", value: 6 },
      ],
      inscripcion: false,
      dialogAlumnos: false,
      cuatrimestre: ["Anual", "Primero", "Segundo"],
      headers: [
        { text: "Id", value: "id" },
        // {text: 'Estado', value: 'estado'},
        { text: "Carrera", value: "carrera.codigo" },
        { text: "Campo", value: "espacio.campo.codigo" },
        { text: "Año", value: "espacio.anio" },

        { text: "Año Lectivo", value: "anio_lectivo" },
        { text: "Cuatrimestre", value: "cuatrimestre" },
        { text: "Espacio", value: "espacio.nombre" },
        // { text: "Comision", value: "comision" },
        // {text: 'Estado', value: 'estado'},
        { text: "Inscribirse", value: "inscribirse" },
      ],
    };
  },
  computed: {
    ...mapState("CursadoModule", ["cursado", "fields"]),
    ...mapGetters("CursadoModule", ["errors"]),
  },
  methods: {
    ...mapActions("CursadoModule", ["editCursado"]),
    guardar() {},
    showAlumnos(item) {
      this.editCursado(item);
      this.dialogAlumnos = true;
    },
    showInscripcion(item) {
      this.editCursado(item);
      this.inscripcion = true;
    },
  },
};
</script>

<style scoped></style>
