<template>
  <div>
    <v-card>
      <v-card-title> Inscripción a Carrera </v-card-title>
      <v-card-text>
        <div>
          <v-form ref="form" @submit.prevent="inscribirse" lazy-validation>
            <v-select
              label="Ingresá la Carrera en la que deseas inscribirte"
              :error-messages="errors.carrera_id"
              :items="filteredCarreras"
              v-model="carrera"
              item-text="nombre"
              return-object
            ></v-select>

            <!--            <v-checkbox-->
            <!--              v-model="mayor25"-->
            <!--              label="Tildar esta opción en caso de ser mayor de 25 años y no tener título secundario"-->
            <!--            ></v-checkbox>-->
            <!--            <v-icon>mdi-info</v-icon>-->
          </v-form>
          <!--          <v-divider></v-divider>-->
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="inscribirse">Inscribirse</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "NuevaCarrera",
  data() {
    return {
      mayor25: false,
      otraCarrera: false,
      vocacional: false,
      alumnoCarrera: {},
      carrera: {},
    };
  },
  computed: {
    ...mapState("CorteInscripcionCarreraModule", ["corteInscripcionCarrera"]),
    ...mapState("AlumnoCarreraModule", ["nuevo"]),
    ...mapGetters("AlumnoCarreraModule", ["errors"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("UserModule", ["user"]),
    // ...mapGetters("NewUserModule",["esIngresante"]),
    esIngresante() {
      return this.user.roles.includes("ingresante");
    },
    filteredCarreras() {
      const carrerasActivas = this.carreras.filter(
        (el) => el.inscripcion_activa && el.estado_carrera === 1
      );
      return carrerasActivas.filter(
        (el) => !this.user.carreras.map((el) => el.id).includes(el.id)
      );
    },
    carreraAlumno() {
      return this.user.carreras ? this.user.carreras[0] : {};
    },
  },
  watch: {
    carrera() {
      this.alumnoCarrera.carrera_id = this.carrera.id;
    },
    mayor25() {
      if (this.mayor25) {
        this.alumnoCarrera.condicion = "mayor25";
      }
    },
    vocacional() {
      if (this.vocacional) {
        this.alumnoCarrera.condicion = "vocacional";
      }
    },
    // user(){
    //   this.alumnoCarrera.alumno_id = this.user.id;
    // }
  },
  methods: {
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreraActivo",
    ]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("AlumnoCarreraModule", [
      "addAlumnoCarrera",
      "sendMailPreinscripcion",
    ]),
    ...mapActions("UserModule", ["getUser"]),
    async inscribirse() {
      this.alumnoCarrera.alumno_id = this.user.id;
      this.alumnoCarrera.condicion = "ingresante";
      this.alumnoCarrera.inscripcion_id = this.corteInscripcionCarrera.id;

      var alumnoCarrera = await this.addAlumnoCarrera(this.alumnoCarrera);
      if (this.nuevo) {
        // console.log(alumnoCarrera);
        if (this.esIngresante) {
          this.sendMailPreinscripcion(alumnoCarrera.id);
        }
        await this.getUser();
        this.$emit("close");
        // this.$router.push("/alumno/completar-inscripcion");
      }
    },
  },
  created() {
    // this.alumnoCarrera.alumno_id = this.user.id;
    this.getCarreras();
    this.getCorteInscripcionCarreraActivo({
      filters: { activo: 1, tipo: "ingresantes" },
    });
  },
};
</script>

<style scoped></style>
