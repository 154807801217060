<template>
  <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
    <div>
      <v-card class="">
        <v-card-title class="text-h6 primary lighten-1 text-light mb-3">
          <div class="d-flex justify-content-center w-100">
            <div>
              <img
                :src="require('@/assets/logoifdc3.png')"
                height="50"
                width="50"
                class="mr-3"
              />
            </div>
            <div class="text-center">
              <div>San Antonio</div>
              <div>Oeste</div>
            </div>
          </div>
          <!--              Login Sistema IFDC SAO-->
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login" ref="loginForm" lazy-validation>
            <v-text-field
              v-model="loginEmail"
              label="Nombre de usuario"
              placeholder="Ingrese su DNI sin puntos, solo números"
              required
              hint="Sin puntos solo números"
            ></v-text-field>
            <v-text-field
              v-model="loginPassword"
              :rules="[rules.required]"
              label="Password"
              type="password"
            ></v-text-field>
          </v-form>
          <div>
            <div class="">
              <v-btn
                x-large
                block
                :disabled="!valid"
                color="success"
                @click="login"
              >
                Login
              </v-btn>
            </div>
          </div>

          <div class="d-sm-flex">
            <div class="w-100">
              <v-btn
                block
                @click="registrar"
                class="mt-3"
                color="secondary"
                text
                >Quiero Registrarme</v-btn
              >
            </div>
            <div class="w-100">
              <v-btn block @click="recuperar" class="mt-3" color="primary" text
                >Olvidé Contraseña</v-btn
              >
            </div>
          </div>
          <!--          <div class="w-100">-->
          <!--            <v-btn x-large block @click="registrar" class="mt-3" color="primary"-->
          <!--              >INSCRIPCIÓN 2024</v-btn-->
          <!--            >-->
          <!--          </div>-->
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import authService from "@/services/auth.service";
import { mapActions } from "vuex";

export default {
  name: "AlumnoLogin",
  computed: {
    loginForm() {
      return {
        login: this.loginEmail,
        password: this.loginPassword,
      };
    },
  },
  methods: {
    ...mapActions("UserModule", ["editUser"]),
    async login() {
      // if (this.$refs.loginForm.validate()) {

      const { success, errors } = await authService.login(this.loginForm);
      if (success) {
        this.$router.push({ name: "Default", force: true });
      } else {
        this.errors = errors;
      }
      // }
    },
    validate() {
      if (this.$refs.loginForm.validate()) {
        this.login();
        // submit form to server/API here...
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    recuperar() {
      this.$router.push({ name: "recuperar" });
    },
    registrar() {
      this.$router.push({ name: "register" });
    },
  },
  data() {
    return {
      dialog: true,
      valid: true,
      email: "",
      password: "",
      verify: "",
      loginPassword: "",
      loginEmail: "",
      loginEmailRules: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      emailRules: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
  created() {
    // this.editUser({});
  },
};
</script>

<style scoped></style>
