<template>
  <div>
    <v-container fluid>
      <slot name="table-title" v-bind="dataProvider">
        <div>
          <h3>{{ modelClass }}</h3>
        </div>
      </slot>
      <slot name="header-buttons">
        <v-row no-gutters>
          <div>
            <v-btn @click="newItem" outlined color="primary">Nuevo</v-btn>
          </div>
          <slot name="add-button"> </slot>
        </v-row>
      </slot>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        :page="page"
        v-model="selectedItems"
        :show-select="showSelect"
        :search="search"
        :items="dataProvider"
        :headers="headers"
        :hide-default-footer="true"
        :items-per-page="perPage"
      >
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template v-slot:item.actions="{ item }">
          <v-icon color="info" class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon color="info" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>

      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :total-visible="7"
          :length="parseInt(totalCount / Math.abs(perPage)) + 1"
        ></v-pagination>
        <v-select
          v-model="perPage"
          label="Items per page"
          :items="[5, 10, 15, 20, 25, 35, 40, 45, 50]"
        ></v-select>
      </div>
    </v-container>
  </div>
</template>

<script>
import modelService from "@/services/model.service";
import { Message, MessageBox } from "element-ui";
// import Form from "@/components/templates/Form";

export default {
  name: "view-index",
  // components: {Form},
  props: {
    showSelect: {
      type: Boolean,
      default: true,
    },
    // pathIndex:String,
    mperPage: {
      type: Number,
      default: 10,
    },
    params: Object,
    expand: String,
    exclude: {
      type: Array,
      default: () => ["id"],
    },
    modelClass: String,
    path: String,
    headers: Array,
  },
  data() {
    return {
      pathIndex: null,
      selectedItems: [],
      search: "",
      page: 1,
      perPage: 10,
      totalCount: null,
      options: {},
      dataProvider: [],
      modal: false,
      item: {},
    };
  },
  watch: {
    page() {
      this.getModels(this.perPage);
    },
    perPage() {
      this.getModels(this.perPage);
    },
  },
  mounted() {
    this.perPage = this.mperPage;
    this.getModels(this.perPage);
    // this.getModels();
  },
  methods: {
    selectAll(event) {
      console.log(event);
      this.selectedItems = [];
      if (event.value) {
        this.selectedItems = event.items;
      }
      console.log("items seleccionados");
      console.log(this.selectedItems);
    },
    select(event) {
      console.log(event);
    },
    errors(errors) {
      this.$emit("errors", errors);
    },

    // updatePerPage(num){
    //   this.perPage = num;
    //   this.getModels();
    // },

    // updatePage(page){
    //   console.log('pagina:')
    //   console.log(page);
    //   this.page = page;
    //   this.getModels();
    // },

    close() {
      this.getModels();
      this.modal = false;
    },

    async getModels(perPage = 0) {
      var path = this.pathIndex ? this.pathIndex : this.path;
      // console.log("busco los modelos");
      const params = {
        ...this.params,
        expand: this.expand,
        search: this.search,
        page: this.page,
        "per-page": perPage,
      };
      console.log(perPage);
      try {
        const { status, data, headers } = await modelService.get(params, path);
        if (status === 200) {
          this.dataProvider = data;
          this.totalCount = +headers["x-pagination-total-count"];
          this.$emit("loaded");
        }
      } catch (e) {
        console.log(e.response);
      }
    },

    editItem(item) {
      console.log(item);
      this.item = item;
      this.modal = true;
      this.$emit("show-model", item);
      this.$emit("edit-model", item);
    },
    async deleteItem(item) {
      try {
        let response = await MessageBox.confirm(
          `Esta acción borrara el registro y no se podra recuperar  , desea continuar?`,
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        console.log(response);
        // console.log(cancel);
        if (response == "confirm") {
          const { status, data } = await modelService.delete(
            item.id,
            this.path,
            true
          );
          if (status === 204) {
            console.log(data);
            this.getModels();
          }
        }
      } catch (e) {
        // console.log(e);
        Message({
          type: "info",
          message: "Delete canceled",
        });
        return e;
      }
    },

    newItem() {
      this.item = {};
      this.modal = true;
      this.$emit("new-model");
      this.$emit("show-modal");
    },
  },
};
</script>

<style scoped></style>
