<template>
  <div class="cursada-index">


    <view-index
        :show-select="false"
        :headers="headers"
        :path="path"
        :params="params"
        :model-class="modelClass"
        :exclude="exclude"
        :expand="expand"
    >



      <template slot="header-buttons">
        <div></div>
      </template>


<template v-slot:item.estado="{item}">
  <v-btn rounded outlined :color="getEstadoColor(item)">
    {{item.estadoNombre}}
  </v-btn>
</template>




    </view-index>



  </div>
</template>

<script>
// import ViewIndex from "@/components/templates/index";
// import MyForm from "@/components/templates/Form";
// import CursadaForm from "@/components/Cursadas/CursadaForm";
// import InscripcionEspacio from "@/components/Cursadas/InscripcionEspacio";
// import MyModal from "@/components/templates/MyModal";
// import ABMtemplate from "@/components/templates/ABMtemplate";
import ViewIndex from "@/components/templates";
export default {
  name: "ExamenPorAlumno",
  components: {
    ViewIndex,
    // ABMtemplate,
    // MyModal,
    // InscripcionEspacio,
    // CursadaForm,
    // MyForm, ViewIndex
  },
  data(){
    return{
      pathModel:"",
      path:"alumno-examen/custom/poralumno",
      vigentes: false,
      params:{},
      inscripcion:false,
      errors:{},
      model:{},
      // pathIndex:"cursados/custom/poralumno",
      modelClass:"Examenes Finales",
      exclude:[],
      expand : ['carrera','materia','examen'].toString(),
      cuatrimestre : ["Anual","Primero","Segundo"],
      headers: [
        {text: 'Id', value: 'id'},
        // {text: 'Estado', value: 'estado'},
        {text: 'Carrera', value: 'carrera.nombre'},
        // {text: 'Año Lectivo', value: 'cursado.anio'},
        // {text: 'Cuatrimestre', value: 'cursado.nombreCuatrimestre'},
        {text: 'Espacio', value: 'materia.nombre'},
        {text: 'Fecha Examen', value: 'examen.fecha'},
        {text: 'Nota', value: 'examen.nota'},
        {text: 'Estado', value: 'estado'},
        // {text: 'Horarios', value: 'horarios'},
        // {text: 'Docentes', value: 'docentes'},
        // {text: 'Fecha Inicio', value: 'fecha_inicio'},
        // {text: 'Fecha Fin', value: 'fecha_fin'},
        // {text: 'Inscripcion', value: 'inscripcion'},
        // {text: 'Alumnos', value: 'alumnos'},
        // {text: 'Acciones', value: 'actions'},
      ]
    }
  },
  methods:{


    getEstadoColor(cur){
      switch (cur.estado) {
        case 0:
          return 'error';
        case 1:
          return 'warning';
        case 2:
          return 'success';
      }
    },

  }

}
</script>

<style scoped>

</style>