import { httpClient } from "@/apis/Api";
import { MessageBox, Message } from "element-ui";
/**
 * Created by TheCodeholic on 3/7/2020.
 */

const modelService = {
  upload(form, url) {
    return httpClient.post(url, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  create(model, url) {
    return httpClient.post(url, model);
  },
  get(params, url) {
    return httpClient.get(url, { params });
  },
  update(model, url) {
    return httpClient.put(`${url}/${model.id}`, model);
  },
  async delete(id, url, sin) {
    if (sin) {
      return httpClient.delete(`${url}/${id}`);
    }
    try {
      // var mthis = Vue.prototype;
      let response = await MessageBox.confirm(
        `Esta acción borrara el registro y no se podra recuperar  , desea continuar?`,
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      console.log(response);
      // console.log(cancel);
      if (response == "confirm") {
        return httpClient.delete(`${url}/${id}`);
      }
    } catch (e) {
      // console.log(e);
      Message({
        type: "info",
        message: "Delete canceled",
      });
      return e;
    }
  },
};

export default modelService;
