<template>
  <v-card>
    <v-card-title
      >Inscripción a Finales
      <v-btn link icon absolute top right @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <div>
        <div>
          <v-select
            hide-details
            v-model="carrera_id"
            :items="user.carreras"
            item-text="nombre"
            item-value="id"
          ></v-select>
        </div>
        <div v-for="(examen, i) in filteredExamenes" :key="examen.id">
          <div class="mt-5" v-if="cambioAnio(examen, i)">
            <v-card dark color="primary">
              <v-card-title> {{ examen.materia.anio }} Año </v-card-title>
            </v-card>
          </div>

          <examen-materia-form
            :selected-examen="selectedExamen"
            :examen="examen"
            @add="addExamen"
            @remove="removeExamen"
          ></examen-materia-form>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="inscribirExamenes" color="primary">Inscribirme</v-btn>
      <v-btn @click="$emit('close')" color="secondary">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ExamenMateriaForm from "@/flavor/alumno/views/Inscripciones/examen/examenMateriaForm";

export default {
  name: "InscripcionExamenesForm",
  components: { ExamenMateriaForm },
  props: {
    instancia: String,
  },
  data() {
    return {
      selectedExamen: [],
      anioCursado: 0,
      carrera_id: null,
      // instancia:null,
    };
  },
  computed: {
    ...mapGetters("ExamenModule", ["porAnios"]),
    ...mapState("ExamenModule", ["examens"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("UserModule", ["user"]),
    ...mapState("AlumnoExamenModule", ["alumnoExamens"]),
    filteredExamenes() {
      return this.porAnios.filter(
        (el) => !this.examenesUsuario.includes(el.id)
      );
    },
    examenesUsuario() {
      return this.alumnoExamens.map((el) => el.examen_id);
    },
  },
  watch: {
    carrera_id() {
      this.selectedExamen = [];
      this.getDisponibleInscripcion({ carrera_id: this.carrera_id });
    },
    user() {
      this.carrera_id =
        this.user.carreras.length > 0 ? this.user.carreras[0].id : null;
    },
  },
  methods: {
    ...mapActions("ExamenModule", ["getDisponibleInscripcion"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("AlumnoExamenModule", [
      "addAlumnoExamen",
      "sendMailInscripcion",
    ]),
    cambioAnio(examen, i) {
      if (i == 0) {
        return true;
      }
      return examen.materia.anio != this.filteredExamenes[i - 1].materia.anio;
    },
    addExamen(id) {
      this.selectedExamen.push(id);
    },
    removeExamen(id) {
      var myIndex = this.selectedExamen.indexOf(id);
      if (myIndex !== -1) {
        this.selectedExamen.splice(myIndex, 1);
      }
    },
    async inscribirExamenes() {
      for (var i in this.selectedExamen) {
        let model = {
          condicion: this.instancia,
          alumno_id: this.user.id,
          examen_id: this.selectedExamen[i],
        };
        const newModel = await this.addAlumnoExamen(model);
        this.sendMailInscripcion(newModel.id);
      }
      console.log("termine de agregar examenes");
      this.selectedExamen = [];
      this.$emit("close");
      this.$emit("reload");
    },
    reload() {
      this.selectedExamen = [];
      this.getDisponibleInscripcion({ carrera_id: this.carrera_id });
    },
  },
  created() {
    this.carrera_id = this.user.carreras[0].id;
  },
};
</script>

<style scoped></style>
