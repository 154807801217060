<template>
  <div>
    <v-dialog v-model="dialogNuevaCarrera" max-width="600">
      <nueva-carrera @close="dialogNuevaCarrera = false"></nueva-carrera>
    </v-dialog>

    <v-row>
      <v-col>
        <v-btn color="primary" @click="dialogNuevaCarrera = true"
          >Nueva Carrera</v-btn
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <h3>Tus inscripciones a Carreras</h3>
    <v-data-iterator :items="alumnoCarreras">
      <template v-slot:default="{ items }">
        <v-row>
          <v-col v-for="item in items" cols="12" md="6" lg="4" :key="item.id">
            <v-card dark :color="getEstado(item).color">
              <v-card-title>{{ item.carrera.nombre }}</v-card-title>
              <div class="px-5">condicion: {{ item.condicion }}</div>
              <!--              <div>estado:{{item.estado}}</div>-->
              <v-card-text>
                <div v-if="getEstado(item).presistema">
                  <v-alert color="warning">
                    Inscripción Pendiente de confirmación
                  </v-alert>
                </div>
                <div v-if="getEstado(item).vencido">
                  <v-alert color="warning">
                    Ha perdido la regularidad en la carrera
                  </v-alert>
                  <v-btn @click="reinscribirse(item)">Reinscribirse</v-btn>
                </div>
                <div v-else>
                  <div v-if="estaInscripto(item)">
                    <v-alert color="success"
                      >Tu inscripción está completa</v-alert
                    >
                  </div>
                  <div v-if="!estaInscripto(item)">
                    <v-alert color="error"
                      >Estas Preinscripto en la Carrera, tu Inscripción estara
                      completa cuando entregues la documentación en el
                      IFDC</v-alert
                    >
                    <v-alert color="info" v-if="item.turno">
                      Ya tenes asignado un turno para presentar la
                      documentación. Debes concurrir al IFDC el día
                      {{ item.turno.fechaFormateada }} a las
                      {{ item.turno.hora }}
                    </v-alert>

                    <v-btn
                      @click="
                        $router.push('/alumno/completar-inscripcion/' + item.id)
                      "
                      color="warning"
                      >Ver requsitos</v-btn
                    >
                  </div>
                </div>

                <!--              <requisitos :inscripcion="item" v-if="!item.estado" :mayor25="item.condicion=='mayor25'"  :carrera="item.carrera"></requisitos>-->
              </v-card-text>
              <v-card-actions>
                <!--              <v-btn  v-if="!item.estado" small color="primary" @click="completarDocumentacion(item)">Completar Documentación</v-btn>-->
                <!--              <v-btn v-else outlined small color="success" >Inscripto</v-btn>-->
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <!--  <v-data-table :headers="headers" :items="alumnoCarreras">-->

    <!--    <template v-slot:item.estado="{item}">-->

    <!--      <v-btn v-if="!item.estado" small color="primary" @click="completarDocumentacion(item)">Completar Documentación</v-btn>-->
    <!--      <v-btn v-else small color="success" >Inscripto</v-btn>-->

    <!--    </template>-->

    <!--  </v-data-table>-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import Requisitos from "@/flavor/alumno/views/ingresantes/requisitos";
import NuevaCarrera from "@/flavor/alumno/views/Inscripciones/carrera/NuevaCarrera";

export default {
  name: "AlumnoCarreraList",
  components: {
    NuevaCarrera,
    // Requisitos
  },
  data() {
    return {
      dialogNuevaCarrera: false,
    };
  },
  computed: {
    ...mapState("CorteInscripcionCarreraModule", ["corteInscripcionCarreras"]),
    ...mapState("AlumnoCarreraModule", ["alumnoCarreras"]),
    ...mapState("UserModule", ["user"]),

    inscripcion() {
      return this.corteInscripcionCarreras.length === 1;
    },

    headers() {
      return [
        { text: "ID", value: "id" },
        { text: "Carrera", value: "carrera.nombre" },
        { text: "Condicion", value: "condicion" },
        { text: "estado", value: "estado" },
      ];
    },
  },
  watch: {
    user() {
      this.reload();
    },
    alumnoCarreras() {
      if (this.alumnoCarreras.length === 0) {
        this.dialogNuevaCarrera = true;
      }
    },
  },
  methods: {
    ...mapActions("CorteInscripcionCarreraModule", [
      "getCorteInscripcionCarreras",
    ]),
    ...mapActions("AlumnoCarreraModule", [
      "getCarrerasPorAlumno",
      "updateAlumnoCarrera",
    ]),
    completarDocumentacion(item) {
      // console.log(item);
      this.$router.push("/alumno/completar-inscripcion/" + item.id);
    },
    reload() {
      this.getCarrerasPorAlumno({ filters: { alumno_id: this.user.id } });
    },

    estaInscripto(item) {
      return (
        item.condicion === "condicional" ||
        item.condicion === "regular" ||
        item.condicion === "vocacional" ||
        item.condicion === "mayor25"
      );
    },

    reinscribirse(item) {
      item.condicion = "ingresante";
      this.updateAlumnoCarrera(item);
    },

    getEstado(item) {
      console.log(item);
      if (this.user.roles.find((el) => el === "alumno-presistema")) {
        return { color: "purple", presistema: true };
      }
      if (item.condicion === "vencido") {
        return { color: "purple", vencido: true };
      }
      if (this.estaInscripto(item)) {
        return { color: "primary" };
      } else {
        return { color: "dark" };
      }
    },
  },
  mounted() {
    this.reload();
    this.getCorteInscripcionCarreras({
      filters: { tipo: ["ingresantes", "alumno-presistema"], activo: 1 },
    });
  },
  // mounted() {
  //   if (this.alumnoCarreras.length == 0) {
  //     this.dialogNuevaCarrera = true;
  //   }
  // },
};
</script>

<style scoped></style>
