<template>
  <v-dialog eager v-model="dialog">
    <v-card>
      <v-card-title
        >Horarios Cursada
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-calendar
          :event-height="100"
          :first-interval="minMax.start"
          :interval-count="minMax.end"
          locale="es"
          :weekdays="weekday"
          ref="calendar"
          :events="horarios"
          color="primary"
          type="week"
        ></v-calendar>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>

      <!--    <v-calendar-->
      <!--        ref="calendar"-->
      <!--        v-model="value"-->
      <!--        :weekdays="weekday"-->
      <!--        :type="type"-->
      <!--        :events="events"-->
      <!--        :event-overlap-mode="mode"-->
      <!--        :event-overlap-threshold="30"-->
      <!--        :event-color="getEventColor"-->
      <!--    ></v-calendar>-->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  name: "CalendarioHorario",
  props: {
    carrera_id: [String, Number],
    // cursos:Array,
  },
  components: {},
  data: () => ({
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    dialog: false,
    cursos: [],
    weekday: [1, 2, 3, 4, 5],
    events: [
      {
        name: "Weekly Meeting",
        start: "2019-01-07 09:00",
        end: "2019-01-07 10:00",
      },
      {
        name: `Thomas' Birthday`,
        start: "2019-01-10",
      },
      {
        name: "Mash Potatoes",
        start: "2019-01-09 12:30",
        end: "2019-01-09 15:30",
      },
    ],
  }),
  computed: {
    ...mapState("NewCursadoModule", ["cursados"]),
    horarios() {
      let events = [];
      for (var i in this.cursos) {
        const color = this.colors[this.rnd(0, this.colors.length - 1)];
        const cursado = this.cursos[i];
        for (var j in cursado.horarios) {
          const horario = cursado.horarios[j];
          let event = {};
          (event.color = color), (event.hora_inicio = horario.hora_inicio);
          event.hora_fin = horario.hora_fin;
          event.name =
            cursado.espacio.nombre + `(comisión:${cursado.comision})`;
          let dia = moment().day(horario.nroDia).format("YYYY/MM/DD");
          let start = moment(dia + " " + horario.hora_inicio);
          event.start = start.format("YYYY-MM-DD HH:mm");
          let end = moment(dia + " " + horario.hora_fin);
          event.end = end.format("YYYY-MM-DD HH:mm");
          events.push(event);
        }
      }
      return events;
    },
    minMax() {
      let min = null;
      let start = null;
      let max = null;
      let end = null;
      for (var i in this.horarios) {
        const horario = this.horarios[i];
        if (!min || horario.hora_inicio < min) {
          min = horario.hora_inicio;
          start = +moment(horario.hora_inicio, "HH:mm").format("HH") - 1;
        }
        if (!max || horario.hora_fin > max) {
          max = horario.hora_fin;
          end = moment(horario.hora_fin, "HH:mm").format("HH");
        }
      }
      end = end - start + 1;
      return { max: max, end: end, min: min, start: start };
    },
  },
  watch: {
    cursos() {
      this.$refs.calendar.checkChange();
    },
  },
  methods: {
    ...mapActions("NewCursadoModule", ["getCursados"]),
    getEventColor(event) {
      return event.color;
    },
    cerrar() {
      this.$emit("close");
      this.dialog = false;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    reload() {
      this.getCursados({
        expand: ["horarios", "espacio"].toString(),
        filters: {
          carrera_id: this.carrera_id,
        },
        options: {
          joins: ["espacio"],
        },
      });
    },
  },
  created() {
    // this.reload();
  },
};
</script>

<style scoped></style>
