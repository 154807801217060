<template>
  <div>
    <v-dialog eager max-width="1080" v-model="dialogExamenes" scrollable>
      <inscripcion-examenes-form
        :instancia="instancia"
        ref="form"
        @reload="reload"
        @close="cerrarForm"
      ></inscripcion-examenes-form>
    </v-dialog>

    <h3>Tus inscripciones a Finales</h3>
    <v-row>
      <v-col>
        <v-btn color="primary" @click="nuevoFinalRegular"
          >Nuevo Final Regular</v-btn
        >
      </v-col>
      <v-col>
        <v-btn color="primary" @click="nuevoFinalLibre"
          >Nuevo Final Libre</v-btn
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-data-iterator :options.sync="options" :items="alumnoExamens">
      <template v-slot:default="{}">
        <div v-for="(porCarrera, codigo) in porCarrera" :key="codigo">
          <v-alert color="primary" dark>
            {{ porCarrera.carrera.nombre }}
          </v-alert>
          <div
            class="mb-3"
            v-for="(porAnio, anio) in porCarrera.anios"
            :key="anio"
          >
            <v-alert color="warning" dark> {{ anio }}° AÑO </v-alert>
            <v-row>
              <v-col
                v-for="item in porAnio"
                cols="12"
                md="6"
                lg="4"
                :key="item.id"
              >
                <v-card
                  dark
                  :color="item.condicion === 'regular' ? 'success' : 'purple'"
                >
                  <v-card-title>
                    <div class="w-100">
                      <div class="bg-secondary p-1 text-center">
                        condicion: {{ item.condicion }}
                      </div>
                      <v-row>
                        <v-col cols="12">
                          <div class="title">{{ item.materia.nombre }}</div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <div class="subtitle-2">
                          {{ item.examen.fechaFormateada }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      v-if="
                        item.examen.puedeInscribirse &&
                        item.condicion !== 'promocion'
                      "
                      @click="borrarItem(item.id)"
                      color="error"
                    >
                      <v-btn link icon><v-icon>mdi-delete</v-icon></v-btn>
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import InscripcionExamenesForm from "@/flavor/alumno/views/Inscripciones/examen/InscripcionExamenesForm";

export default {
  name: "InscripcionesExamenAlumno",
  components: {
    InscripcionExamenesForm,
  },
  data() {
    return {
      options: {
        itemsPerPage: -1,
      },
      dialogExamenes: false,
      filters: {},
      instancia: null,
    };
  },
  computed: {
    ...mapGetters("AlumnoExamenModule", ["porCarrera"]),
    ...mapState("ExamenModule", ["examens"]),
    ...mapState("AlumnoExamenModule", ["alumnoExamens"]),
    ...mapState("UserModule", ["user"]),
    headers() {
      return [
        { text: "ID", value: "id" },
        { text: "Carrera", value: "carrera.nombre" },
        { text: "Condicion", value: "condicion" },
        { text: "estado", value: "estado" },
      ];
    },
    // porCarrera(){
    //   const carreras = this.alumnoExamens.reduce((carreras, item) => {
    //     let tag = item.materia.anio + '° AÑO';
    //     tag = item.materia.anio  ;
    //     let tag2 = item.carrera.codigo;
    //
    //     carreras[tag2] =(carreras[tag2] || {});
    //     carreras[tag2].carrera = item.carrera
    //     carreras[tag2].anios = (carreras[tag2].anios || {})
    //
    //
    //     // const anio = (carreras[tag2].anios[tag] || []);
    //     // anio.push(item);
    //
    //
    //     carreras[tag2].anios[tag] = (carreras[tag2].anios[tag] || []);
    //
    //     carreras[tag2].anios[tag].push(item);
    //     return carreras;
    //   }, {});
    //   console.log(carreras);
    //   return carreras;
    // },
  },
  watch: {
    user() {
      this.filters.alumno_id = this.user.id;
      // this.reload();
    },
    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
    dialogExamenes() {
      if (this.dialogExamenes) {
        this.$refs.form.reload();
      } else {
        this.$refs.form.selectedExamen = [];
      }
    },
  },
  methods: {
    ...mapActions("ExamenModule", ["getExamens", "getDisponibleInscripcion"]),
    ...mapActions("AlumnoExamenModule", [
      "getAlumnoExamenPorCursado",
      "getAlumnoExamens",
      "deleteAlumnoExamen",
      "getAlumnoExamensPorAlumno",
    ]),

    nuevoFinalRegular() {
      this.instancia = "regular";
      this.dialogExamenes = true;
    },
    nuevoFinalLibre() {
      this.instancia = "libre";

      this.dialogExamenes = true;
    },

    reload() {
      console.log(this.user);
      this.filters = { alumno_id: this.user.id };
      this.getAlumnoExamensPorAlumno({
        options: this.options,
        filters: this.filters,
        expand: ["alumno", "carrera", "materia", "examen"].toString(),
        // search:this.search.tag,
      });
    },
    cerrarForm() {
      this.$refs.form.selectedExamen = [];
      this.dialogExamenes = false;
    },
    async borrarItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteAlumnoExamen(id);
      }
    },
  },
  mounted() {
    // this.reload()
  },
};
</script>

<style scoped></style>
