<template>
  <div>
    <v-dialog v-model="dialog" max-width="900">
      <v-card>
        <v-card-title>
          <div class="pb-4">
            <v-btn @click="dialog = false" link icon absolute right top
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <v-carousel height="auto">
            <v-carousel-item v-for="(i, field) in documentacion" :key="field">
              <h5>{{ field }}</h5>
              <div v-if="documentacion[field].type == 'application/pdf'">
                <v-btn @click="page--">-</v-btn>
                <v-btn @click="page++">+</v-btn>
                <span>pagina {{ page }}</span>
                <pdf :src="documentacion[field].data" :page="page"></pdf>
              </div>
              <!--              <pdf   :src="documentacion[field].data"></pdf>-->
              <v-img v-else contain :src="documentacion[field].data"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        <div>
          <h4>Documentación {{ user.nombre }} {{ user.apellido }}</h4>
          <v-btn link icon @click="$emit('close')" absolute right top>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="(i, field) in documentacion" :key="field">
            <v-card
              v-if="
                alumnoCarrera.documentacionAlumno &&
                alumnoCarrera.documentacionAlumno[field]
              "
            >
              <v-card-title>
                {{ field }}
                <v-btn
                  @click="borrar(field)"
                  color="error"
                  link
                  icon
                  absolute
                  right
                  top
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-card-text @click="dialog = true">
                <pdf
                  v-if="documentacion[field].type == 'application/pdf'"
                  :src="documentacion[field].data"
                ></pdf>
                <v-img
                  v-else
                  width="200"
                  height="200"
                  contain
                  :src="documentacion[field].data"
                ></v-img>
              </v-card-text>
            </v-card>
            <h5></h5>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <!--        <v-btn @click="reload">reload</v-btn>-->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import pdf from "vue-pdf";

export default {
  name: "ImagenesDocumentacionIngresante",
  props: {
    user: Object,
    inscripcion_id: [String, Number],
  },
  components: {
    pdf,
  },
  data() {
    return {
      // inscripcion:{},
      page: 1,
      // key:0,
      dialog: false,
    };
  },
  computed: {
    ...mapState("AlumnoCarreraModule", [
      "documentacion",
      "key",
      "alumnoCarrera",
    ]),
  },
  watch: {
    user() {
      this.reload();
    },
  },
  methods: {
    ...mapActions("AlumnoCarreraModule", [
      "getAlumnoCarrera",
      "deleteDocumentacion",
    ]),
    ...mapActions("AlumnoCarreraModule", [
      "getFrenteDni",
      "getDniDorso",
      "getCuil",
      "getFormularioInscripcion",
      "getFormularioMedico",
      "updateAlumnoCarrera",
    ]),
    async borrar(field) {
      // this.alumnoCarrera[field]=null;
      await this.deleteDocumentacion({
        field: field,
        inscripcion: this.inscripcion_id,
      });
      this.reload();
    },
    reload() {
      this.getFrenteDni({ inscripcion: this.inscripcion_id });
      this.getDniDorso({ inscripcion: this.inscripcion_id });
      this.getCuil({ inscripcion: this.inscripcion_id });
      this.getFormularioInscripcion({ inscripcion: this.inscripcion_id });
      this.getFormularioMedico({ inscripcion: this.inscripcion_id });
    },
  },
  created() {
    this.getAlumnoCarrera({
      id: this.inscripcion_id,
      expand: ["documentacionAlumno"],
    });
    this.reload();
  },
};
</script>

<style scoped></style>
