<template>
  <v-card dark color="primary">
    <v-card-title>Documentación en formato Digital</v-card-title>
    <v-card-text>
      <p class="subtitle-1">
        En caso de que existan restricciones para que las/os ingresantes puedan
        entregar la documentación de manera presencial, se habilitará este
        espacio para que adjunten la documentación en formato digital.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="black"
        dark
        @click="$router.push('/alumno/subir-documentacion/' + inscripcion_id)"
        >Ir a Documentación Digital</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "DocumentacionCard",
  props: {
    inscripcion_id: [String, Number],
  },
};
</script>

<style scoped></style>
