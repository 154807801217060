<template>
  <v-dialog persistent max-width="600" v-model="dialog">
    <v-card>
      <v-card-title class="text-h6 teal lighten-1 text-light mb-3">
        <div class="d-flex justify-content-center w-100">
          <div>
            <img
              :src="require('@/assets/logoifdc3.png')"
              height="50"
              width="50"
              class="mr-3"
            />
          </div>
          <div class="text-center">
            <div>U.N.E.S.</div>
            <div>San Antonio</div>
            <div>Oeste</div>
          </div>
        </div>
        <!--              Login Sistema IFDC SAO-->
      </v-card-title>
      <v-card-text>
        <v-alert color="warning">Las Inscripciones están cerradas</v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FinInscripcion",
  data() {
    return {
      dialog: true,
    };
  },
};
</script>

<style scoped></style>
