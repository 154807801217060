<template>
  <div>
    <v-card>
      <v-card-title> Documentación digital </v-card-title>
      <v-card-text>
        <v-form>
          <v-file-input
            enctype="multipart/form-data"
            v-model="documentacion.dni_frente"
            label="Ingrese foto de dni frente"
          ></v-file-input>
          <v-file-input
            enctype="multipart/form-data"
            v-model="documentacion.dni_dorso"
            label="Ingrese foto de dni Dorso"
          ></v-file-input>
          <v-file-input
            v-model="documentacion.cuil"
            enctype="multipart/form-data"
            label="Ingrese Constancia de Cuil"
          ></v-file-input>
          <v-file-input
            v-model="documentacion.formulario_inscripcion"
            enctype="multipart/form-data"
            label="Ingrese Formulario de inscripción completado y firmado"
          ></v-file-input>
          <v-file-input
            v-model="documentacion.formulario_medico"
            enctype="multipart/form-data"
            label="Ingrese Formulario de Estudios Médicos completado y firmado"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="subirDocumentacion">Subir</v-btn>
      </v-card-actions>
    </v-card>
    <imagenes-documentacion-ingresante
      ref="imagenes"
      :user="user"
      :inscripcion_id="inscripcion_id"
    ></imagenes-documentacion-ingresante>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ImagenesDocumentacionIngresante from "@/flavor/alumno/views/Inscripciones/documentacion/ImagenesDocumentacionIngresante";

export default {
  name: "DocumentacionIngresante",
  props: {
    inscripcion_id: [String, Number],
  },
  components: { ImagenesDocumentacionIngresante },
  data() {
    return {
      documentacion: {},
    };
  },
  computed: {
    ...mapState("UserModule", ["user"]),
  },
  methods: {
    ...mapActions("UserModule", ["getUser"]),
    ...mapActions("AlumnoCarreraModule", [
      "uploadDocumentacion",
      "getAlumnoCarrera",
    ]),
    async subirDocumentacion() {
      // this.setErrors({});
      const formData = new FormData();
      // for(var i  in this.imageFile){
      if (this.documentacion.dni_frente) {
        formData.append(
          "dni_frente",
          this.documentacion.dni_frente,
          this.documentacion.dni_frente.name
        );
      }
      if (this.documentacion.dni_dorso) {
        formData.append(
          "dni_dorso",
          this.documentacion.dni_dorso,
          this.documentacion.dni_dorso.name
        );
      }
      if (this.documentacion.cuil) {
        formData.append(
          "cuil",
          this.documentacion.cuil,
          this.documentacion.cuil.name
        );
      }
      if (this.documentacion.formulario_inscripcion) {
        formData.append(
          "formulario_inscripcion",
          this.documentacion.formulario_inscripcion,
          this.documentacion.formulario_inscripcion.name
        );
      }
      if (this.documentacion.formulario_medico) {
        formData.append(
          "formulario_medico",
          this.documentacion.formulario_medico,
          this.documentacion.formulario_medico.name
        );
      }
      formData.append("inscripcion", this.inscripcion_id);
      // formData.append('imageFile', this.imageFile.file, this.imageFile.name)
      // this.imagen.FK_ID_PROPIEDAD = this.propiedad.ID_PROPIEDAD;
      await this.uploadDocumentacion(formData);
      this.$refs.imagenes.reload();
      // this.getUser({});
      // this.getAvatar(this.user);
    },
  },
  created() {
    // this.documentacion.inscripcion_id = this.inscripcion_id;
  },
};
</script>

<style scoped></style>
