<template>
  <div>
    <v-dialog v-model="modal" max-width="800" persistent>
      <v-card>
        <v-card-title
          >Correlativas de {{ cursado.espacio.nombre }}</v-card-title
        >
        <v-card-text>
          <v-alert color="error" dark
            >Para poder cursar este Espacio deberás contar con las siguientes
            correlativas</v-alert
          >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Materia</th>
                  <th class="text-left">Condición</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="materia in uCurricularCarrera.correlativasCursado"
                  :key="materia.id"
                >
                  <td>{{ materia.correlativa.searchTag }}</td>
                  <td>
                    <v-chip color="success">
                      {{ materia.tipo.nombre }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="aceptar">Aceptar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ModalCorrelativoCursado",
  props: {
    cursado: Object,
    // modal:Boolean,
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    ...mapState("UCurricularCarreraModule", ["uCurricularCarrera"]),
  },
  methods: {
    ...mapActions("UCurricularCarreraModule", ["getUCurricularCarrera"]),
    cancelar() {
      this.modal = false;
      this.$emit("cancelar");
    },
    aceptar() {
      this.modal = false;
      this.$emit("aceptar");
    },
  },
  created() {},
};
</script>

<style scoped></style>
