<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title> Horarios Por Carrera </v-card-title>
      <v-card-text>
        <v-autocomplete
          :items="filteredHorarios"
          item-text="nombre"
          item-value="codigo"
          v-model="carrera"
          outlined
          label="Seleccione una carrera"
        >
        </v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-btn color="secondary" :href="horario.link" target="_blank">
          Descargar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "HorariosCarrera",
  computed: {
    ...mapState("UserModule", ["user"]),
    // ...mapState("CarreraModule",["carreras"]),
    filteredHorarios() {
      return this.carreras;
    },

    horario() {
      return this.horarios.find((el) => el.carrera === this.carrera);
    },
  },
  data() {
    return {
      // carreras:[],
      dialog: false,
      carrera: null,
      horarios: [
        {
          carrera: "PEI",
          link:
            "https://drive.google.com/file/d/1TYJYAcU5Bq_noKD6DmEhy6jf08uLJmaA/view",
        },
        {
          carrera: "PEP",
          link:
            "https://drive.google.com/file/d/1-HJEIeOFHC3fLMmejWcTKcoQOEsPs3oy/view",
        },
        {
          carrera: "PESB",
          link:
            "https://drive.google.com/file/d/1I3-L6jt1N14NITpW81nEwdQPfQ6gDBSr/view",
        },
        {
          carrera: "AEJ",
          link:
            "https://drive.google.com/file/d/1gD0JR9Ss8ZkLdlwfD8_EidEfzpNym44J/view",
        },
        {
          carrera: "ALIL",
          link:
            "https://drive.google.com/file/d/1v7uXntdmPwVOHBlLA0ihumZyaw8NbCEC/view",
        },
        {
          carrera: "TSAE",
          link:
            "https://drive.google.com/file/d/135C6ItIWE_1BXVXDI3KUI2k90eNu6GhR/view",
        },
        {
          carrera: "GUAR",
          link:
            "https://drive.google.com/file/d/1XCmFGaXJcuErz9N_jstDeELCOgyWLUxG/view",
        },
      ],
    };
  },
  methods: {
    ...mapActions("CarreraModule", ["getCarreras"]),
    descargarHorario() {
      window.location.href = this.horario.link;
    },
  },
  created() {
    this.getCarreras();
    this.carreras = this.user.carreras;
    this.carrera = this.carreras[0] ? this.carreras[0].codigo : null;
  },
};
</script>

<style scoped></style>
