<script setup>
export default {
  name: "Veedores",
  components: {},
};
</script>

<template>
  <div>
    <h3>Veedores Estudiantiles</h3>
    <div class="row mb-3">
      <div class="col-md">
        <v-btn
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSeKXwXq89F-dqfyLy2gurzCKcscDT4yHId6AZ1DkLjD7tbUBw/closedform"
          color="primary"
          >Quiero Ser Veedor</v-btn
        >
      </div>

      <div class="col-md">
        <v-btn
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSehZFjGlMVXVVeWFOzPneLHtJkB5ULEMlHBFxYb3pMt89AQWQ/closedform"
          color="deep-purple"
          dark
        >
          Necesito que haya un veedor en mi mesa
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
