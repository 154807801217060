var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',[_vm._v("Cursados")]),_c('v-select',{attrs:{"label":"Carrera","items":_vm.user.carreras,"item-text":"nombre","item-value":"id"},model:{value:(_vm.carreraFilter),callback:function ($$v) {_vm.carreraFilter=$$v},expression:"carreraFilter"}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"search":_vm.search,"show-select":true,"items":_vm.cursadoPorCarrera,"headers":_vm.headers},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"item.aprobado",fn:function(ref){
var item = ref.item;
return [(item.notaAlumno)?_c('div',[(item.notaAlumno.cursado_aprobado)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v(" mdi-checkbox-marked-circle ")]):(
            (item.notaAlumno.nota_alumno < 7 &&
              item.notaAlumno.nota_alumno > 0) ||
            item.notaAlumno.fecha_abandono
          )?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v(" mdi-alert-circle ")]):_vm._e()],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }