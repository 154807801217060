var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cursado-a-b-m-alum',{attrs:{"headers":_vm.headers,"user":_vm.user},scopedSlots:_vm._u([{key:"other-form",fn:function(){return [_c('cursada-form',{attrs:{"errors":_vm.errors,"item":_vm.cursado},on:{"guardar":_vm.guardar}})]},proxy:true},{key:"item.cuatrimestre",fn:function(){return [_vm._v(" "+_vm._s(_vm.cuatrimestre[_vm.cursado.cuatrimestre])+" ")]},proxy:true},{key:"item.inscripcion",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","rounded":"","color":item.inscripciones ? 'success' : 'error'},on:{"click":function($event){return _vm.showInscripcion(item)}}},[_vm._v(" Inscr ")])]}},{key:"item.alumnos",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/alumno-cursada/' + item.id)}}},[_vm._v(" Alum ")])]}},{key:"item.docentes",fn:function(ref){
var item = ref.item;
return _vm._l((item.docentes),function(docente){return _c('span',{key:docente.id},[_vm._v(" "+_vm._s(docente.apellido)+" ")])})}},{key:"item.horarios",fn:function(ref){
var item = ref.item;
return _vm._l((item.horarios),function(horario){return _c('span',{key:horario.id},[_vm._v(" "+_vm._s(_vm.dias[horario.dia].text)+" de "+_vm._s(horario.hora_inicio)+" a "+_vm._s(horario.hora_fin)+" ")])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }