import "./plugins/axios";
import Vue from "vue";
import App from "./App.vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-search-select/dist/VueSearchSelect.css";
import router from "./router/alumno";
import VCalendar from "v-calendar";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/es";

import "element-ui/lib/theme-chalk/index.css";
import vuetify from "./plugins/vuetify";
import JsonExcel from "vue-json-excel";
import store from "./store";

// import VueConfirmDialog from "vue-confirm-dialog";

import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});

// Vue.use(VueConfirmDialog);
// Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

Vue.component("downloadExcel", JsonExcel);

Vue.use(ElementUI);
Vue.use(locale);

Vue.use(Loading);

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
