<template>
  <v-container fluid>
    <v-dialog v-model="dialogCorrelativa" width="500">
      <v-card>
        <v-card-title class="text-h6 purple lighten-1 text-light">
          <span v-if="tieneCorrelativas"
            >Correlativas de {{ cursado.espacio.nombre }}</span
          >
          <span v-else>{{ cursado.espacio.nombre }}</span>
        </v-card-title>

        <v-card-text>
          <v-divider></v-divider>
          <div v-if="tieneCorrelativas">
            <p>
              Para poder inscribirse en este espacio curricular usted debera
              contar con las siguientes requisitos:
            </p>
            <v-list dense>
              <v-list-item v-for="item in cursado.correlativas" :key="item.id">
                <v-list-item-content>
                  <strong>
                    {{ item.correlativa.searchTag }} ({{ item.tipo.nombre }})
                  </strong>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div v-else>
            <p>
              Esta a punto de inscribirse en el espacio curricular
              {{ cursado.espacio.searchTag }}
            </p>
          </div>
          <v-switch v-model="mail" label="Enviar Email a mi Correo" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="inscribirme">
            SI, Inscribirme
          </v-btn>
          <v-btn color="error" text @click="dialogCorrelativa = false">
            <span v-if="tieneCorrelativas"> Me faltan Requisitos </span>
            <span v-else> Cancelar </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h3>Cursados</h3>

    <v-row>
      <v-select
        class="mr-3"
        label="Carrera"
        v-model="options.carrera_id"
        :items="user.carreras"
        item-text="nombre"
        item-value="id"
      ></v-select>

      <v-select
        clearable
        class="mr-3"
        label="Ingrese Año de la Carrera"
        v-model="options.anio"
        :items="[1, 2, 3, 4]"
      ></v-select>

      <v-select
        clearable
        class="mr-3"
        label="Ingrese Campo de la formacion"
        :items="campos"
        v-model="options.campo_id"
        item-value="id"
        item-text="nombre"
      ></v-select>
    </v-row>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :search="search"
      v-model="selectedItems"
      :show-select="true"
      :items="cursadoPorCarrera"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.cuatrimestre="{ item }">
        {{ item.nombreCuatrimestre }}
      </template>

      <!--      <template v-slot:item.estado="{item}">-->
      <!--        <v-chip outlined   v-if="getEstadoEditable(item)"  >{{getEstadoEditable(item)}}</v-chip>-->
      <!--      </template>-->

      <template v-slot:item.inscribirse="{ item }">
        <!--          <v-chip outlined  color="primary" v-if="getEstadoEditable(item)"  >{{getEstadoEditable(item)}}</v-chip>-->
        <div v-if="estoyInscripto(item)">
          <v-btn rounded @click="desinscribirme(item)" color="error"
            >anular</v-btn
          >
        </div>
        <div v-else>
          <v-btn rounded @click="confirmInscripcion(item)" color="success"
            >inscribirse</v-btn
          >
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "InscripcionCursadoABMAlum",

  props: {
    // user:Object,
    uploadPath: { type: String, default: "cursados" },
    headers: Array,
    only: Array,
  },
  data() {
    return {
      options: {},
      tieneCorrelativas: false,
      mail: true,
      acceptDialog: false,
      dialogCorrelativa: false,
      defaultCarrera: null,
      carreraFilter: null,
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("CampoModule", ["getCampos"]),
    ...mapActions("CursadoModule", [
      "vigentesPorAlumno",
      "porAlumno",
      "getCursados",
      "getFields",
      "addCursado",
      "updateCursado",
      "deleteCursado",
      "editCursado",
      "showDialog",
      "hideDialog",
    ]),
    ...mapActions("AlumnoCursadoModule", [
      "getAlumnoCursados",
      "addAlumnoCursado",
      "deleteAlumnoCursado",
    ]),

    confirmInscripcion(cursada) {
      this.editCursado(cursada);
      if (cursada.correlativas.length > 0) {
        this.tieneCorrelativas = true;
      } else {
        this.tieneCorrelativas = false;
      }
      this.dialogCorrelativa = true;
    },
    inscribirme() {
      this.addAlumnoCursado({
        alumno_id: this.user.id,
        cursado_id: this.cursado.id,
        mail: this.mail,
      });
      this.dialogCorrelativa = false;
    },
    desinscribirme(cursada) {
      var model = this.alumnoCursados.find((el) => el.cursado_id == cursada.id);
      if (model) {
        this.deleteAlumnoCursado(model.id);
      }
    },

    estoyInscripto(cursada) {
      var model = this.alumnoCursados.find(
        (el) => el.cursado_id === cursada.id
      );
      // console.log(model);
      //console.log(cursada);
      if (model) {
        // console.log(model);
        return true;
      }
      return false;
    },
    close() {
      this.hideDialog();
      this.vigentesPorAlumno();
    },
    onSubmit() {
      // this.addCursado(this.cursado);
      this.cursado.mail = true;
      this.cursado.id
        ? this.updateCursado(this.cursado)
        : this.addCursado(this.cursado);
    },
    newItem() {
      // this.fetchCursado();
      this.editCursado({});
      this.showDialog();
    },
    editItem(item) {
      this.editCursado(item);
      this.showDialog();
      // this.showCursadoDialog();
    },
    deleteItem(id) {
      this.deleteCursado(id);
    },
  },
  computed: {
    ...mapState("CampoModule", ["campos"]),
    ...mapState("UserModule", ["user"]),
    // ...mapGetters("UserModule",["defaultCarrera"]),
    ...mapState("CursadoModule", ["cursados", "dialog", "cursado", "fields"]),
    ...mapState("AlumnoCursadoModule", ["alumnoCursados"]),
    cursadoPorCarrera() {
      // var filtrados = this.cursados.slice();
      return this.cursados;
      // return this.cursados.filter((el) => el.carrera.id == this.defaultCarrera);
    },
    // ...mapGetters("CursadoModule",["cursadoList","cursadoDialog","newCursadoRecord","cursado","cursadoFields","cursadoErrors"]),
    // ...mapGetters("CursadoModule",["errors"]),
  },
  watch: {
    options: {
      handler() {
        this.vigentesPorAlumno(this.options);
      },
      deep: true,
    },
  },
  created() {
    this.getCampos();
    this.options.carrera_id = this.user.carreras[0].id;
    this.vigentesPorAlumno(this.options);
    this.getAlumnoCursados();
    // this.getFields();
  },
};
</script>

<style scoped></style>
