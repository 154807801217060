<template>
  <v-card dark>
    <v-card-title></v-card-title>
    <v-card-text>
      <h5>La documentación a presentar para completar la inscripción es:</h5>
      <p v-if="inscripcion.condicion == 'mayor25'">
        Te has inscripto como estudiante mayor de 25 años sin título secundario.
        <a href="#">Ver Resolución</a>
      </p>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            D.N.I. (original o copia autenticada).
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Acta/Partida o Certicado de nacimiento (original o copia
            autenticada).
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="inscripcion.condicion != 'mayor25'">
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Título o Constancia de título en trámite de estudios secundarios
            (original o copia autenticada).
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div>
              Constancia de CUIL.
              <a
                target="_blank"
                href="http://servicioswww.anses.gov.ar/ConstanciadeCuil2/Inicio.aspx"
                >Anses <v-icon color="primary">mdi-download</v-icon></a
              >
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div>
              Solicitud de inscripción
              <a target="_blank" :href="carrera.formulario_inscripcion">
                Descargar aqui <v-icon color="primary">mdi-download</v-icon>
              </a>
              Completar y firmar.
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <p class="subtitle-1">
        En caso de no contar con las copias autenticadas, podrás presentar
        originales y fotocopias, las cuales serán autenticadas en la
        institución, sin costo alguno.
      </p>
      <v-divider></v-divider>
      <h5>Salud estudiantil</h5>
      <p>
        Las/os ingresantes deberán realizarse exámenes médicos: clínico y
        foniátrico
        <a
          class="text-decoration-none"
          target="_blank"
          :href="carrera.formulario_medico"
          >Descargar formularios Aquí
          <v-icon color="primary">mdi-download</v-icon></a
        >
      </p>
      <p>
        Los certificados podrán ser presentados al momento de la inscripción o
        de manera posterior a ella.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "requisitos",
  props: {
    inscripcion: Object,
    mayor25: Boolean,
    carrera: Object,
  },
};
</script>

<style scoped></style>
