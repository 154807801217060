<template>
  <v-dialog v-model="dialog" max-width="900">
    <v-card>
      <v-card-title> Cursados Superpuestos</v-card-title>
      <v-card-text>
        <div v-for="cursado in cursados" :key="cursado.id">
          <div class="d-flex">
            <div>
              {{ cursado.espacio.searchTag }}
            </div>
            <v-spacer></v-spacer>
            <div v-for="horario in cursado.horarios" :key="horario.id">
              Horario: {{ horario.dia }} {{ horario.hora_inicio }}
              {{ horario.hora_fin }}
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HorariosSuperpuestos",
  data() {
    return {
      dialog: false,
      superpuestos: [],
      cursados: [],
    };
  },
};
</script>

<style scoped></style>
