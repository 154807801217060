<template>
  <v-row>
    <modal-correlativo
      ref="corre"
      :examen="examen"
      @aceptar="addExamen"
      @cancelar="removeExamen"
    ></modal-correlativo>
    <v-col>
      <div class="d-table-row">
        <div class="d-table-cell">
          <v-checkbox
            class="my-checkbox"
            hide-details
            @change="changeInscribirme"
            v-model="inscribirme"
            :label="label"
          ></v-checkbox>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ModalCorrelativo from "@/flavor/alumno/views/Inscripciones/examen/modalCorrelativo";
import { mapActions, mapState } from "vuex";
export default {
  name: "examenMateriaForm",
  components: { ModalCorrelativo },
  props: {
    examen: Object,
    selectedExamen: Array,
  },
  computed: {
    ...mapState("UCurricularCarreraModule", ["uCurricularCarrera"]),
    label() {
      return (
        this.examen.materia.nombre +
        " - " +
        this.examen.fechaFormateada +
        " (Turno: " +
        this.examen.turno +
        ")"
      );
    },
  },

  watch: {
    selectedExamen() {
      if (this.selectedExamen.length === 0) {
        this.inscribirme = false;
      }
    },
  },
  data() {
    return {
      modal: false,
      inscribirme: false,
      model: {},
    };
  },
  methods: {
    ...mapActions("UCurricularCarreraModule", ["getUCurricularCarrera"]),
    async changeInscribirme() {
      if (this.inscribirme) {
        await this.getUCurricularCarrera(this.examen.materia_id);
        if (this.uCurricularCarrera.correlativas.length !== 0) {
          this.$refs.corre.modal = true;
        } else {
          this.addExamen();
        }
      }
    },
    addExamen() {
      this.$emit("add", this.examen.id);
    },
    removeExamen() {
      this.inscribirme = false;
      this.$emit("remove", this.examen.id);
    },
  },
};
</script>

<style scoped>
.my-checkbox label {
  margin-bottom: 0;
}
</style>
