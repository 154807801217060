<template>
  <div class="container">
    <h4>{{ carrera.nombre }}</h4>
    <v-divider></v-divider>
    <v-alert dark color="error">
      <h5>
        Esta es la documentación obligatoria que debés presentar en la
        Secretaría de Estudiantes para efectuar tu inscripción. Al correo que
        consignaste, se te enviará un turno (fecha y hora) en el que deberás
        presentarte en el IFDC para realizar el trámite.
        <!--        al {{ carrera.nombre }}-->
      </h5>
    </v-alert>

    <!--    <v-select-->
    <!--      label="Seleccione Carrera"-->
    <!--      :items="user.carreras"-->
    <!--      v-model="carrera"-->
    <!--      item-text="nombre"-->
    <!--      return-object-->
    <!--    ></v-select>-->

    <requisitos :inscripcion="alumnoCarrera" :carrera="carrera"></requisitos>
    <v-divider></v-divider>
    <documentacion-card
      v-show="false"
      :inscripcion_id="inscripcion_id"
    ></documentacion-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Requisitos from "@/flavor/alumno/views/ingresantes/requisitos";
import DocumentacionCard from "@/flavor/alumno/views/Inscripciones/documentacion/DocumentacionCard";

export default {
  name: "CompletarInscripcion",
  props: {
    inscripcion_id: [String, Number],
  },
  components: { DocumentacionCard, Requisitos },
  data() {
    return {
      carrera: {},
      // alumnoCarrera: {},
    };
  },
  computed: {
    ...mapState("AlumnoCarreraModule", ["alumnoCarrera"]),
    carreraAlumno() {
      return this.user.carreras ? this.user.carreras[0] : {};
    },
    ...mapState("UserModule", ["user"]),
    ...mapState("CarreraModule", ["carreras"]),
  },
  methods: {
    ...mapActions("AlumnoCarreraModule", ["getAlumnoCarrera"]),
  },
  async created() {
    await this.getAlumnoCarrera({ id: this.inscripcion_id });
    this.carrera = this.alumnoCarrera.carrera;
  },
};
</script>

<style scoped></style>
