<template>
  <v-container fluid>
    <h3>Cursados</h3>

    <v-select
      label="Carrera"
      v-model="carreraFilter"
      :items="user.carreras"
      item-text="nombre"
      item-value="id"
    ></v-select>

    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      :search="search"
      v-model="selectedItems"
      :show-select="true"
      :items="cursadoPorCarrera"
      :headers="headers"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>

      <template v-slot:item.aprobado="{ item }">
        <div v-if="item.notaAlumno">
          <v-icon
            v-if="item.notaAlumno.cursado_aprobado"
            color="success"
            class="mr-2"
          >
            mdi-checkbox-marked-circle
          </v-icon>
          <v-icon
            v-else-if="
              (item.notaAlumno.nota_alumno < 7 &&
                item.notaAlumno.nota_alumno > 0) ||
              item.notaAlumno.fecha_abandono
            "
            color="error"
            class="mr-2"
          >
            mdi-alert-circle
          </v-icon>
          <!--        <v-icon v-else color="warning" class="mr-2">-->
          <!--          mdi-alert-circle-->
          <!--        </v-icon>-->
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon color="info" class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon color="info" @click="deleteItem(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "CursadoABMAlum",

  props: {
    // user:Object,
    uploadPath: { type: String, default: "cursados" },
    headers: Array,
    only: Array,
  },
  data() {
    return {
      carreraFilter: null,
      // carreraFilter: 1,
      selectedItems: [],
      search: "",
    };
  },
  methods: {
    ...mapActions("CursadoModule", [
      "porAlumno",
      "getCursados",
      "getFields",
      "addCursado",
      "updateCursado",
      "deleteCursado",
      "editCursado",
      "showDialog",
      "hideDialog",
    ]),
    ...mapActions("AlumnoCursadoModule", ["getAlumnoCursados"]),
    close() {
      this.hideDialog();
      this.getCursados();
    },
    onSubmit() {
      // this.addCursado(this.cursado);
      this.cursado.mail = true;
      this.cursado.id
        ? this.updateCursado(this.cursado)
        : this.addCursado(this.cursado);
    },
    newItem() {
      // this.fetchCursado();
      this.editCursado({});
      this.showDialog();
    },
    editItem(item) {
      this.editCursado(item);
      this.showDialog();
      // this.showCursadoDialog();
    },
    deleteItem(id) {
      this.deleteCursado(id);
    },
  },
  // watch:{
  //   user(){
  //     this.carreraFilter = this.user.carreras[0];
  //   }
  // },
  computed: {
    ...mapState("UserModule", ["user"]),
    ...mapGetters("UserModule", ["defaultCarrera"]),
    ...mapState("CursadoModule", ["cursados", "dialog", "cursado", "fields"]),
    ...mapState("AlumnoCursadoModule", ["alumnoCursados"]),
    cursadoPorCarrera() {
      return this.cursados.filter((el) => el.carrera.id == this.carreraFilter);
    },
    // ...mapGetters("CursadoModule",["cursadoList","cursadoDialog","newCursadoRecord","cursado","cursadoFields","cursadoErrors"]),
    // ...mapGetters("CursadoModule",["errors"]),
  },
  created() {
    this.carreraFilter = this.user.carreras[0].id;
    this.porAlumno();
    // this.getAlumnoCursados();
    this.getFields();
  },
};
</script>

<style scoped></style>
