<template>
  <div>
    <v-dialog
      :eager="false"
      scrollable
      persistent
      v-model="exito"
      max-width="600"
    >
      <v-card dark color="primary">
        <v-card-title>¡Te has registrado en el sistema con éxito!</v-card-title>
        <v-card-text>
          <p class="subtitle-1">
            Ya podés ingresar. Tu usuario es tu DNI y la contraseña es la que
            creaste en el formulario anterior.
          </p>

          <requisitos></requisitos>
        </v-card-text>
        <v-card-actions>
          <!--          <v-btn @click="$router.go()">Volver</v-btn>-->
          <v-btn @click="$router.push('/login')">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable persistent v-model="dialog" max-width="1200">
      <v-card class="">
        <v-card-title class="text-h6 teal lighten-1 text-light mb-3">
          <div class="d-flex justify-content-center w-100">
            <div>
              <img
                :src="require('@/assets/logoifdc3.png')"
                height="50"
                width="50"
                class="mr-3"
              />
            </div>
            <div class="text-center">
              <div>U.N.E.S.</div>
              <div>San Antonio Oeste</div>
            </div>
          </div>
          <!--              Login Sistema IFDC SAO-->
        </v-card-title>
        <v-card-text>
          <v-row class="d-none">
            <v-col>
              <v-card dark class="my-3">
                <v-card-text>
                  <p class="subtitle-1">
                    Tu inscripción será definitiva cuando presentes en la
                    secretaría de estudiantes, la documentación completa en
                    soporte papel y la solicitud de inscripción firmada por vos.
                  </p>
                  <p class="subtitle-1">
                    Antes de comenzar el proceso de inscripción asegurate de
                    tener una dirección de correo electrónico válida y que
                    funcione correctamente. Completá los campos de este
                    formulario con atención.
                    <strong>LUEGO NO PODRÁS MODIFICARLOS.</strong>
                  </p>
                  <p class="subtitle-1">
                    Al finalizar tu registro podrás acceder al sistema,
                    ingresando tu DNI como usuario y la contraseña que
                    generaste.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-form class="pt-2" ref="form" lazy-validation>
            <v-row>
              <v-col cols sm="12" md="6" class="">
                <v-select
                  outlined
                  label="Ingresá la Carrera en la que deseas inscribirse"
                  :error-messages="errors.carrera_id"
                  :items="filteredCarreras"
                  v-model="user.carrera"
                  item-text="nombre"
                  return-object
                ></v-select>
                <v-text-field
                  :error-messages="errors.nombre"
                  outlined
                  v-model="user.nombre"
                  label="Nombres"
                  placeholder="Ingrese Nombres"
                  required
                  hint="Tal como figura en tu DNI"
                ></v-text-field>

                <v-text-field
                  :error-messages="errors.apellido"
                  outlined
                  v-model="user.apellido"
                  placeholder="Ingrese Apellidos"
                  label="Apellido"
                  maxlength="20"
                  required
                  hint="Tal como figura en tu DNI"
                ></v-text-field>
                <v-text-field
                  :error-messages="errors.telefono"
                  outlined
                  v-model="user.telefono"
                  placeholder="Ingrese Teléfono"
                  label="Teléfono"
                ></v-text-field>
              </v-col>
              <v-col cols sm="12" md="6" class="">
                <v-text-field
                  :error-messages="errors.email"
                  outlined
                  placeholder="Ingrese su email"
                  v-model="user.email"
                  label="E-mail"
                  required
                ></v-text-field>
                <!--                <v-text-field-->
                <!--                  :error-messages="errorEmail"-->
                <!--                  outlined-->
                <!--                  placeholder="Vuelva a Ingresar su email"-->
                <!--                  v-model="emailRepeat"-->
                <!--                  label="Repetir E-mail"-->
                <!--                  required-->
                <!--                ></v-text-field>-->

                <v-text-field
                  :error-messages="errors.username"
                  outlined
                  placeholder="Ingrese su DNI"
                  v-model="dni"
                  label="DNI"
                  required
                  hint="sin puntos solo números"
                ></v-text-field>
                <!--                <v-text-field-->
                <!--                  :error-messages="errorDni"-->
                <!--                  outlined-->
                <!--                  placeholder="Vuelva a Ingresar su DNI"-->
                <!--                  v-model="dniRepeat"-->
                <!--                  label="Repetir DNI"-->
                <!--                  required-->
                <!--                  hint="sin puntos solo números"-->
                <!--                ></v-text-field>-->
                <v-text-field
                  type="password"
                  :error-messages="errors.password"
                  outlined
                  placeholder="Ingrese una clave"
                  v-model="user.password"
                  label="Contraseña"
                  required
                  hint="la contraseña debe tener al menos 8 caracteres"
                ></v-text-field>
                <!--                <v-text-field-->
                <!--                  type="password"-->
                <!--                  :error-messages="errorPassword"-->
                <!--                  outlined-->
                <!--                  placeholder="Vuelva a Ingresar una clave"-->
                <!--                  v-model="passwordRepeat"-->
                <!--                  label="Repetir Contraseña"-->
                <!--                  required-->
                <!--                  hint="la contraseña debe tener al menos 6 caracteres"-->
                <!--                ></v-text-field>-->
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="w-100">
            <div class="d-flex justify-content-end">
              <vue-recaptcha
                class=""
                ref="invisibleRecaptcha"
                :sitekey="siteKey"
                :loadRecaptchaScript="true"
                @verify="onVerify"
                @expired="onExpired"
              >
                <!--              <v-btn>No soy un robot</v-btn>-->
              </vue-recaptcha>
              <small class="error--text">
                {{ recaptchaError }}
              </small>
            </div>
            <v-btn class="m-2" @click="registrar" link block color="primary"
              >Registrarse</v-btn
            >
            <!--            <v-btn-->
            <!--              class="m-2"-->
            <!--              @click="$router.push('/login')"-->
            <!--              link-->
            <!--              block-->
            <!--              color="success"-->
            <!--              >Volver a Login</v-btn-->
            <!--            >-->
            <!--            <v-btn-->
            <!--              class="m-2"-->
            <!--              @click="$router.push('/auth/recuperar')"-->
            <!--              link-->
            <!--              block-->
            <!--              color="warning"-->
            <!--              >Recuper Contraseña</v-btn-->
            <!--            >-->
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import authService from "@/services/auth.service";

export default {
  name: "RegistrarAlumno",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      carrera: null,
      errores: false,
      exito: false,
      dialog: true,
      user: { email: "", password: "" },
      dni: "",
      dniRepeat: "",
      emailRepeat: "",
      passwordRepeat: "",
      recaptchaError: "",
      recatcha: false,
      siteKey: "6LdQzBwdAAAAAC5oXQnqjT-ngJyEd2luQzWbxXRJ",
    };
  },
  computed: {
    ...mapState("UserModule", ["nuevo"]),
    ...mapGetters("UserModule", ["errors"]),
    ...mapState("CarreraModule", ["carreras"]),
    filteredCarreras() {
      const carrerasActivas = this.carreras.filter(
        (el) => el.estado_carrera && el.inscripcion_activa
      );
      return carrerasActivas;
      // return carrerasActivas.filter(
      //     (el) => !this.user.carreras.map((el) => el.id).includes(el.id)
      // );
    },
    errorDni() {
      // return "";
      // if (this.dni.replace(/ /g, "") !== this.dniRepeat.replace(/ /g, "")) {
      //   return "Los dni no son iguales";
      // }
      return "";
    },
    errorEmail() {
      // return "";
      // if (
      //   this.user.email.replace(/ /g, "") !== this.emailRepeat.replace(/ /g, "")
      // ) {
      //   return "Los email no son iguales";
      // }
      return "";
    },
    errorPassword() {
      // return "";
      // if (
      //   this.user.password.replace(/ /g, "") !==
      //   this.passwordRepeat.replace(/ /g, "")
      // ) {
      //   return "Las claves no son iguales";
      // }
      return "";
    },
  },
  watch: {
    dni() {
      this.user.dni = this.dni;
      this.user.username = this.dni;
    },
    user() {
      if (Object.keys(this.errors)) {
        // console.log("clear errors");
        this.setErrors({});
      }
    },
  },
  methods: {
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapMutations("UserModule", ["setErrors"]),
    ...mapActions("UserModule", ["addIngresante"]),
    validar() {
      return true;
      // return !this.errorDni && !this.errorPassword && !this.errorEmail;
    },
    async registrar() {
      if (!this.recatcha) {
        this.recaptchaError = "Tildar para verificar el formulario";
        return;
      }
      if (this.validar()) {
        await this.addIngresante(this.user);
        if (this.nuevo) {
          const { success } = await authService.login({
            login: this.user.username,
            password: this.user.password,
          });
          if (success) {
            this.$router.push({ name: "Default", force: true });
          }
        }
      }
    },
    onVerify() {
      // console.log(response);
      this.recatcha = true;
    },

    onExpired: function () {
      // console.log("Expired");
      this.recatcha = false;
    },
  },
  created() {
    this.getCarreras();
  },
};
</script>

<style scoped></style>
